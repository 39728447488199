gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrollSmoother);

ScrollSmoother.create({
   smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
   effects: true,           // looks for data-speed and data-lag attributes on elements
   smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
});

// SITE LOGO SCROLL SLOW

 ScrollTrigger.create({
   trigger: ".herologoemb",
   pin: true,
   start: "top top",
   end: "+=20000",
   
 });

// SITE LOGO MASK MOVE

 gsap.to(".masker", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom+=100px",
   end: "top top",
   },
   y: 600
 });

// SITE LOGO SCALE

 gsap.to(".embcont", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom+=100px",
   end: "top top",
   },
   scale: .7
 });

// SITE LOGO BG

 gsap.to(".menubg", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom-=100px",
   end: "top top",
   },
   scale: 1
 });

// HERO BOTTOM LINES

 gsap.to(".scaleline", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom-=100px",
   end: "top top",
   },
   scaleX: .4
 });

// HERO BOTTOM LEFT DIAG LINE

 gsap.to(".bl-line", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom+=100px",
   end: "top top",
   },
   x: -200,
   y: 300
 });

// HERO BOTTOM RIGHT DIAG LINE

 gsap.to(".br-line", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom+=100px",
   end: "top top",
   },
   x: 200,
   y: 300
 });

// HERO TOP LEFT DIAG LINE

 gsap.to(".tl-line", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom+=100px",
   end: "top top",
   },
   x: -200,
   y: -300
 });

 // HERO TOP RIGHT DIAG LINE

 gsap.to(".tr-line", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom+=100px",
   end: "top top",
   },
   x: 200,
   y: -300
 });

// ABOUT TXT LINE HEIGHT

 gsap.to(".animatedtxt p", {
   scrollTrigger: {
   trigger: ".mainsec",
   scrub: true,
   start: "top bottom-=200px",
   end: "top top",
   },
   lineHeight: 2
 });

// ABOUT BG FADE

 gsap.to(".aboutbg", {
   scrollTrigger: {
   trigger: ".aboutbg",
   scrub: true,
   start: "top center",
   end: "top top",
   },
   opacity: .6,
   x: 20
 });

// ABOUT TXT MASK

 ScrollTrigger.create({
   trigger: ".textmask",
   pin: true,
   start: "top center",
   end: "+=800",
 });

 // COPPERSPLATTER

 gsap.to(".coppersplatter", {
  scrollTrigger: {
  trigger: ".coppersplatter",
  scrub: true,
  start: "bottom bottom+=200px",
  end: "top top",
  },
  y: -50
});

 // COPPERJD

 // gritcircle

 gsap.to(".gritcircle", {
  scrollTrigger: {
  trigger: ".hero",
  scrub: true,
  start: "bottom bottom",
  end: "+=2000",
  },
  rotation: 10
});

// FAV LOOP

 $(function(){
   var $fav = $('.fav');
   var index = 0;
   var sources = [
     'img/fav1.png',
     'img/fav2.png',
   ];
   
   setInterval(function () {
     index ^= 1;
     $fav.attr('href', sources[index]);
   }, 3000);
 });

 var bodyheight = 0;

 function resizer() {
  var heroheight = $("#hero").height();
  var mainsecheight = $("#mainsec").height();
  bodyheight = mainsecheight + heroheight + 75;
  $("#smooth-content").height(bodyheight);
}

 $(window).on('load', function() { // makes sure the whole site is loaded 
  $('#pcircle').fadeOut(); // will first fade out the loading animation 
  $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
  $('body').delay(550).css({'overflow':'visible'});
  resizer();
  $("body").height(bodyheight);
});

$(window).on("resize",function() {
  resizer();
});